import { useState } from 'react';
import './App.css';
import { HashRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import InputContainer from './InputContainer';
import Login from './Login';
import PrintHistory from './PrintHistory';
import NavBar from './NavBar';
import CreateUserForm from './components/createUser';
import ChangePassword from './components/changePassword';
import PrintButton from './components/test'
import toast, { Toaster } from 'react-hot-toast';
import UserList from './components/UserList';

function App() {
  const [file, setFile] = useState();
  const [authenticated, setAuthenticated] = useState(false);

  const handleChangeFile = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
    }
  };

  const handleAuthentication = (isAuthenticated) => {
    setAuthenticated(isAuthenticated);
  };



  return (
    <Router>
      {/* <Routes>
        <Route path="/" element={<Login onAuthentication={handleAuthentication} />} />

        <Route
            path="/*"
            element={ */}
              <AppContent
                authenticated={authenticated}
                handleChangeFile={handleChangeFile}
                file={file}
                handleAuthentication={handleAuthentication}
              />
            {/* }
          />
      </Routes> */}
    </Router>
  );
}

function AppContent({ handleChangeFile, file, handleAuthentication }) {
  const location = useLocation();

  // Conditionally render NavBar only on InputContainer route
  const isInputContainerRoute = location.pathname.includes('/printer');
  const isHistoryRoute = location.pathname.includes('/printHistory');
  const isCreateUserRoute = location.pathname.includes('/createUser');
  const isUserListRoute = location.pathname.includes('/userList');
  const isChangePasswordRoute = location.pathname.includes('/changePassword');

  return (
    <div>
      <div className="App">
      <div><Toaster /></div>
        <Routes>
          <Route path="/" element={<Login onAuthentication={handleAuthentication} />} />
        </Routes>
          {(isInputContainerRoute && <NavBar />) || (isHistoryRoute && <NavBar/>) || (isCreateUserRoute && <NavBar/>) || (isChangePasswordRoute && <NavBar/>) || (isUserListRoute && <NavBar/>)}
        <Routes>
          <Route path="/printer" element={<InputContainer handleChangeFile={handleChangeFile} file={file} />} />
          <Route path="/printHistory" element={<PrintHistory />} />
          <Route path="/createUser" element={<CreateUserForm />} />
          <Route path="/changePassword" element={<ChangePassword />} />
          <Route path="/test" element={<PrintButton />} />
          <Route path="/userList" element={<UserList />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
