import '../LoginForm.css'
import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Form from 'react-bootstrap/Form'
import { Button } from 'react-bootstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

const LoginForm = () => {
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [role, setRole] = useState('user')
  const [email, setEmail] = useState('')
  const [isValidEmail, setIsValidEmail] = useState(false)
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_BASE_URL

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value
    setEmail(inputEmail)

    // Perform custom email validation
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(inputEmail)
    setIsValidEmail(isValid)
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    const newPassword = e.target.value.replace(/\s/g, '')
    setPassword(newPassword)
  }

  const handleUsernameChange = (e) => {
    setUsername(e.target.value)
    const newUsername = e.target.value.replace(/\s/g, '')
    setUsername(newUsername)
  }

  const handleSelectChange = (event) => {
    setRole(event.target.value)
  }

  useEffect(() => {
    console.log(role)
  }, [role])

  useEffect(() => {
    const checkTokenValidity = async () => {
      // Retrieve the token from local storage
      const authToken = localStorage.getItem('printer_authToken')

      if (!authToken) {
        // Token is not available, redirect to the login page
        navigate('/')
        return
      }

      try {
        // Check the validity of the token by making an API call
        const response = await fetch('https://wb.tetonltd.com/validateJwt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: authToken,
          }),
        })

        if (!response.ok) {
          localStorage.removeItem('printer_authToken')
          // Token is not valid, redirect to the login page
          navigate('/')
        }
      } catch (error) {
        console.error('An error occurred during token validation:', error)
        // Handle error if needed
        navigate('/')
        return
      }
    }
    // Call the function to check token validity on every render
    checkTokenValidity()
  }, [])

  const handleCreateUser = async (e) => {
    toast.dismiss()
    e.preventDefault()
    setLoading(true)
    if (!isValidEmail) {
      setLoading(false)
      toast.error('Email not valid', { autoClose: 2000 })
    } else {
      const authToken = localStorage.getItem('printer_authToken')
      try {
        const response = await fetch(baseUrl + '/createUser', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            username: username,
            password: password,
            role: role,
            email: email,
          }),
        })

        if (response.ok) {
          setLoading(false)
          toast.success(
            'User created successfully, a mail has been sent to the new user email',
            { autoClose: 2000 }
          )
          const responseData = await response.json()
        } else {
          setLoading(false)
          const responseData = await response.json()
          const message = responseData.message

          console.log(message)
          toast.error(`Error: ${message}`, { autoClose: 2000 })
        }
      } catch (error) {
        setLoading(false)
        console.error('An error occurred during creation: ', error)
        toast.error(`An error occurred during creation: ${error}`, {
          autoClose: 2000,
        })
      }
    }
  }

  return (
    <div id="login-form">
      <h1>CREATE USER</h1>
      <form>
        <label htmlFor="username">USERNAME</label>
        <input
          type="text"
          id="username"
          name="username"
          value={username}
          onChange={handleUsernameChange}
          placeholder="Enter new username"
          required
        />
        <label htmlFor="password">PASSWORD</label>

        <div className="newPasswordContainer">
          <input
            type={showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Enter new password"
            required
          />
          <Button
            id="newPasswordButton"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              height: '50px',
              width: '50px',
              backgroundColor: 'transparent',
              border: 'none',
            }}
          >
            {showPassword ? (
              <FaEyeSlash style={{ color: 'black' }} />
            ) : (
              <FaEye style={{ color: 'black' }} />
            )}
          </Button>
        </div>

        <label htmlFor="username">EMAIL</label>
        <input
          type="email"
          id="email"
          name="email"
          value={email}
          onChange={handleEmailChange}
          placeholder="Enter user email"
          required
          style={{
            backgroundColor: 'rgb(232, 240, 254)',
            border: '1px solid lightgray',
          }}
        />
        <label htmlFor="username">USER ROLE</label>
        <Form.Select
          aria-label="Default select example"
          style={{
            backgroundColor: 'rgb(232, 240, 254)',
            border: '1px solid lightgray',
            marginBottom: '17px',
            paddingBottom: '12px',
            paddingTop: '12px',
          }}
          onChange={handleSelectChange}
          value={role}
          required
        >
          <option value="user">User</option>
          <option value="admin">Admin</option>
        </Form.Select>

        <input
          type="submit"
          onClick={handleCreateUser}
          style={{
            backgroundColor: loading ? 'gray' : 'green',
            cursor: loading ? 'not-allowed' : 'pointer',
          }}
          value="CREATE USER"
        />
      </form>
      <div>
        <Toaster />
      </div>
    </div>
  )
}

export default LoginForm
