// @flow strict
import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import 'bootstrap/dist/css/bootstrap.min.css'
import Form from 'react-bootstrap/Form'
import Loader from './components/Loader'
import { PDFDocument } from 'pdf-lib'

function InputContainer() {
  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [loadingPage, setLoadingPage] = useState(false)
  const fileInputRef = useRef(null)
  const [inputValue, setInputValue] = useState('')
  const [uploadFileName, setUploadFileName] = useState('')
  const navigate = useNavigate()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const [show, setShow] = useState(false)
  const [colorValue, setColorValue] = useState('mono')
  const [qualityValue, setQualityValue] = useState('normal')
  const [copies, setCopies] = useState(1)
  const [rangeInput, setRangeInput] = useState('')
  const baseUrl = process.env.REACT_APP_BASE_URL

  const [pdfUrl, setPdfUrl] = useState('')

  const resetState = () => {
    setFile(null)
    setLoading(false)
    setLoadingPage(false)
    setInputValue('')
    setUploadFileName('')
    setScreenWidth(window.innerWidth)
    setShow(false)
    setColorValue('mono')
    setQualityValue('normal')
    setCopies(1)
    setRangeInput('')
  }

  function range(start, end) {
    let length = end - start + 1
    return Array.from({ length }, (_, i) => start + i - 1)
  }

  const handleInputChange = (e) => {
    // Allow only non-negative numbers

    if (e.target.value > 4) {
      const newValue = Math.max(1, 4)
      setCopies(newValue)
    } else {
      const newValue = Math.max(1, e.target.value)
      setCopies(newValue)
    }
  }

  const handleKeyDown = (e) => {
    // Prevent the input of the '-' (minus) sign
    if (e.key === '-') {
      e.preventDefault()
    }
  }

  const handleColorValue = (event) => {
    setColorValue(event.target.value)
  }

  const handleQualityValue = (event) => {
    setQualityValue(event.target.value)
  }

  const handleClose = () => {
    setShow(false)
    resetState()
  }
  const handleShow = () => {
    toast.dismiss()
    if (file) {
      setShow(true)
    } else {
      toast.error('Please provide a file to print', { autoClose: 2000 })
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    const checkTokenValidity = async () => {
      // Retrieve the token from local storage
      const authToken = localStorage.getItem('printer_authToken')

      if (!authToken) {
        // Token is not available, redirect to the login page
        navigate('/')
        return
      }

      try {
        let url = baseUrl+'/validateJwt';
        // Check the validity of the token by making an API call
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: authToken,
          }),
        })

        if (!response.ok) {
          localStorage.removeItem('printer_authToken')
          // Token is not valid, redirect to the login page
          navigate('/')
        }
      } catch (error) {
        console.error('An error occurred during token validation:', error)
        // Handle error if needed
        navigate('/')
        return
      }
    }
    // Call the function to check token validity on every render
    checkTokenValidity()
  }, [])

  useEffect(() => {
    console.log('the upload file name is: ', uploadFileName)
  }, [uploadFileName])

  const handleChangeFile = async (e) => {
    var fileType = null
    var selectedFile = null
    if (fileInputRef.current && fileInputRef.current.files.length > 0) {
      selectedFile = fileInputRef.current.files[0]
      fileType = selectedFile.type
    }

    if (fileType === 'application/pdf') {
      // File is a PDF
      setFile(selectedFile)
      // setFile(selectedFile)
      const file = e.target.files[0]
      const reader = new FileReader()

      reader.onload = (event) => {
        const url = event.target.result
        setPdfUrl(url)
      }

      reader.readAsDataURL(file)
    } else {
      setLoadingPage(true) // Start loading
      setFile(selectedFile)
      const apiUrl = baseUrl + '/save-pdf'
      const formData = new FormData()
      formData.append('file', selectedFile)
      const authToken = localStorage.getItem('printer_authToken')

      try {
        const response = await axios.post(apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
          },
        })
        console.log(response)
        if (response.status === 200) {
          const fileName = response.data.fileName
          console.log('the file name is: ', fileName)
          setUploadFileName(fileName)
          console.log('the upload file name is: ', uploadFileName)
          setPdfUrl(`data:application/pdf;base64,${response.data.data}`)
        } else {
          // console.log('Error: ',responseBody);
          toast.error('File upload failed', { autoClose: 2000 })
        }
      } catch (error) {
        console.error('Error:', error)
      } finally {
        setLoadingPage(false) // Stop loading
      }
    }
  }

  const printFunction = async (ipBlocks) => {
    const numberCheckRegex = /^\d+$/
    const rangeCheckRegex = /^\d+-\d+$/
    if (
      !numberCheckRegex.test(rangeInput) &&
      !rangeCheckRegex.test(rangeInput) &&
      rangeInput != 'All'
    ) {
      setLoading(false)
      toast.error('Invalid format of page range field', { autoClose: 2000 })
    } else {
      try {
        // console.log("the file name is: ",fileName);
        const apiUrl = baseUrl + '/executePrintJob'
        const formData = new FormData()
        formData.append('file', file)
        formData.append('color_mode', colorValue)
        formData.append('print_quality', qualityValue)
        formData.append('copies', copies)
        formData.append('fileName', uploadFileName)
        formData.append('range', rangeInput)
        formData.append('ip', ipBlocks)


        const authToken = localStorage.getItem('printer_authToken')
        const response = await axios.post(apiUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${authToken}`,
          },
        })
        console.log(response)
        if (response.data.status === 200) {
          resetState()
          console.log('here')
          setFile(null)
          setInputValue('')
          toast.success('Print successful', { autoClose: 2000 })
        } else if (response.data.status === 405) {
          resetState()
          setFile(null)
          setInputValue('')
          toast.error('Unsupported media type', { autoClose: 2000 })
        } else if (response.data.status === 404) {
          resetState()
          setFile(null)
          setInputValue('')
          toast.error('Printing not allowed from your location.', { autoClose: 2000 })
        }else {
          resetState()
          setFile(null)
          setInputValue('') 
          toast.error('Print failed', { autoClose: 2000 })
        }
      } catch (error) {
        resetState()
        console.error('Error uploading file:', error.message)
        if (error.response.data.status === 402) {
          toast.error('Invalid credentials', { autoClose: 2000 })
        } else if (error.response.data.status === 406){
          toast.error('Print not allowd from your location', { autoClose: 2000 })
        } else {
          toast.error('Print failed', { autoClose: 2000 })
        }
      } finally {
        resetState()
        setLoading(false)
      }
    }
  }

  const handleFileUpload = async () => {
    toast.dismiss()
    handleClose()
    setLoading(true)
    try {
      const response = await fetch(`https://api.ipify.org?format=json`)

      const data = await response.json()
      if (response.ok) {
        const ipAddress = data.ip
        const ipBlocks = ipAddress.split('.').slice(0, 3).join('.')
        // console.log(ipBlocks)
       
          printFunction(ipBlocks)
        
      } else {
        setLoading(false)
        console.error('Error fetching print history')

        toast.error('Error fetching ip address', { autoClose: 2000 })
      }
    } catch (error) {
      setLoading(false)
      toast.error('Something went wrong', { autoClose: 2000 })
    }
  }

  return (
    <div className="input-wrapper">
      {/* Beautiful Header */}
      <div
        style={{
          backgroundColor: '#333',
          color: 'white',
          padding: '10px',
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 'bold',
          ...(screenWidth <= 1000 && { width: '100%' }),
        }}
      >
        TETON PRIVATE LIMITED PRINTER
      </div>
      {/* Beautiful Email Input */}

      <div
        className="input-container"
        style={{
          ...(screenWidth <= 1000 && { width: '100%' }),
        }}
      >
        <div>
          <input
            type="file"
            accept=".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
            onChange={handleChangeFile}
            className="input-field"
            ref={fileInputRef}
          />
          {file ? (
            <div className="file-info">
              <p>{file.name}</p>
              <p>
                <span>Type:</span> {file.type}
              </p>
            </div>
          ) : (
            <div>
              <p
                className="choose-title"
                style={{
                  ...(screenWidth <= 1000 && {
                    fontSize: '10px',
                    letterSpacing: '0',
                  }),
                }}
              >
                Choose or drag and drop a file to print
              </p>
              <p
                className="accept-title"
                style={{
                  ...(screenWidth <= 1000 && {
                    fontSize: '10px',
                    letterSpacing: '0',
                  }),
                }}
              >
                Accepted file types: .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx
              </p>
            </div>
          )}
        </div>
      </div>
      {/* {loadingPage && <Loader />} */}
      <button
        onClick={handleShow}
        disabled={loading}
        style={{
          marginTop: '20px',
          backgroundColor: loading ? 'gray' : 'green',
          color: 'white',
          padding: '10px 20px',
          border: 'none',
          borderRadius: '5px',
          cursor: loading ? 'not-allowed' : 'pointer',
        }}
      >
        {loading ? 'Printing...' : 'Print'}
      </button>
      <Modal show={show} onHide={handleClose} dialogClassName="modal-90w">
        <Modal.Header
          closeButton
          style={{
            backgroundColor: 'black',
            color: 'white',
          }}
        >
          <Modal.Title>PRINTER SETTINGS</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display: 'flex' }}>
          <div style={{ flex: '1', marginRight: '20px' }}>
            {pdfUrl && !loadingPage && (
              <iframe
                src={pdfUrl}
                title="PDF Viewer"
                width="100%"
                height="600px"
                frameBorder="0"
                aria-label="PDF Viewer"
                onError={() => console.log('Error rendering PDF')}
              />
            )}
            {loadingPage && <Loader />}
          </div>
          <div style={{ flexBasis: '20%' }}>
            <label htmlFor="username">COLOR MODE</label>
            <Form.Select
              aria-label="Default select example"
              style={{
                backgroundColor: 'rgb(232, 240, 254)',
                border: '1px solid lightgray',
                marginBottom: '17px',
                paddingBottom: '12px',
                paddingTop: '12px',
              }}
              onChange={handleColorValue}
              value={colorValue}
              required
            >
              <option value="color">Color</option>
              <option value="mono">Black and White</option>
            </Form.Select>
            <label htmlFor="username">QUALITY</label>
            <Form.Select
              aria-label="Default select example"
              style={{
                backgroundColor: 'rgb(232, 240, 254)',
                border: '1px solid lightgray',
                marginBottom: '17px',
                paddingBottom: '12px',
                paddingTop: '12px',
              }}
              onChange={handleQualityValue}
              value={qualityValue}
              required
            >
              <option value="normal">Normal</option>
              <option value="high">High</option>
            </Form.Select>
            <label htmlFor="username">COPIES</label>
            <input
              type="number"
              value={copies}
              // onChange={(e) => setCopies(e.target.value)}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              style={{
                width: '100%',
                padding: '12px',
                border: '1px solid lightgray',
                borderRadius: '5px',
                fontSize: '16px',
                boxSizing: 'border-box',
                marginBottom: '20px',
                backgroundColor: 'rgb(232, 240, 254)',
              }}
              maxLength={4}
              required
            />
            <label htmlFor="username">PAGE RANGE OR SPECIFIC PAGE</label>
            <input
              type="text"
              placeholder="Ex: 1, 1-4, All"
              value={rangeInput}
              onChange={(e) => setRangeInput(e.target.value)}
              style={{
                width: '100%',
                padding: '12px',
                border: '1px solid lightgray',
                borderRadius: '5px',
                fontSize: '16px',
                boxSizing: 'border-box',
                marginBottom: '20px',
                backgroundColor: 'rgb(232, 240, 254)',
              }}
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="success" onClick={handleFileUpload}>
            Save and print
          </Button>
        </Modal.Footer>
      </Modal>
      <div>
        <Toaster />
      </div>
    </div>
  )
}

export default InputContainer
