import '../LoginForm.css'
import React, { useState, useEffect } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

const ChangePassword = ({ onAuthentication }) => {
  const [authenticated, setAuthenticated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [oldPassword, setOldPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [newPasswordAgain, setNewPasswordAgain] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [showNewPasswordAgain, setShowNewPasswordAgain] = useState(false)
  const navigate = useNavigate()
  const baseUrl = process.env.REACT_APP_BASE_URL

  useEffect(() => {
    const checkTokenValidity = async () => {
      // Retrieve the token from local storage
      const authToken = localStorage.getItem('printer_authToken')

      if (!authToken) {
        // Token is not available, redirect to the login page
        navigate('/')
        return
      }

      try {
        // Check the validity of the token by making an API call
        const response = await fetch('https://wb.tetonltd.com/validateJwt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: authToken,
          }),
        })

        if (!response.ok) {
          localStorage.removeItem('printer_authToken')
          // Token is not valid, redirect to the login page
          navigate('/')
        }
      } catch (error) {
        console.error('An error occurred during token validation:', error)
        // Handle error if needed
        navigate('/')
        return
      }
    }
    // Call the function to check token validity on every render
    checkTokenValidity()
    // Assuming you have an API endpoint to fetch print history
  }, [])

  const handlePasswordChange = (e) => {
    setOldPassword(e.target.value)
    const newPassword = e.target.value.replace(/\s/g, '')
    setOldPassword(newPassword)
  }
  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value)
    const newPassword = e.target.value.replace(/\s/g, '')
    setNewPassword(newPassword)
  }
  const handleNewPasswordChangeAgain = (e) => {
    setNewPasswordAgain(e.target.value)
    const newPassword = e.target.value.replace(/\s/g, '')
    setNewPasswordAgain(newPassword)
  }
  const handleChangePassword = async (e) => {
    const authToken = localStorage.getItem('printer_authToken')
    e.preventDefault()
    toast.dismiss()

    setLoading(true)
    if (newPassword !== newPasswordAgain) {
      toast.error('Error: New password doesnt match', { autoClose: 2000 })
      setLoading(false)
    } else if (oldPassword === newPassword) {
      toast.error('Error: New password and old password are same')
      setLoading(false)
    } else {
      try {
        const response = await fetch(baseUrl + '/changePassword', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authToken}`,
          },
          body: JSON.stringify({
            oldPassword: oldPassword,
            newPassword: newPassword,
          }),
        })

        const responseData = await response.json()
        if (response.ok) {
          toast.success('Password change successful', { autoClose: 2000 })
          setLoading(false)
        } else {
          setLoading(false)
          const message = responseData.message
          // Handle login error
          console.error('Password change failed.')
          toast.error(`Error: ${message}`, { autoClose: 2000 })
        }
      } catch (error) {
        setLoading(false)
        console.error('An error occurred during password change: ', error)
        toast.error('An error occurred during password change: ', {
          autoClose: 2000,
        })
      }
    }
  }

  return (
    <div id="login-form">
      <h1>CHANGE PASSWORD</h1>
      <form>
        <label htmlFor="oldPassword">OLD PASSWORD</label>
        <div className="newPasswordContainer">
          <input
            type={showOldPassword ? 'text' : 'password'}
            id="oldPassword"
            name="oldPassword"
            value={oldPassword}
            onChange={handlePasswordChange}
            placeholder="Enter your current password"
          />
          <Button
            id="newPasswordButton"
            onClick={() => setShowOldPassword(!showOldPassword)}
            style={{
              height: '50px',
              width: '50px',
              backgroundColor: 'transparent',
              border: 'none',
            }}
          >
            {showOldPassword ? (
              <FaEyeSlash style={{ color: 'black' }} />
            ) : (
              <FaEye style={{ color: 'black' }} />
            )}
          </Button>
        </div>
        <label htmlFor="newPassword">NEW PASSWORD</label>
        <div className="newPasswordContainer">
          <input
            type={showPassword ? 'text' : 'password'}
            id="newPassword"
            name="newPassword"
            value={newPassword}
            onChange={handleNewPasswordChange}
            placeholder="Enter your new password"
          />
          <Button
            id="newPasswordButton"
            onClick={() => setShowPassword(!showPassword)}
            style={{
              height: '50px',
              width: '50px',
              backgroundColor: 'transparent',
              border: 'none',
            }}
          >
            {showPassword ? (
              <FaEyeSlash style={{ color: 'black' }} />
            ) : (
              <FaEye style={{ color: 'black' }} />
            )}
          </Button>
        </div>

        <label htmlFor="newPasswordAgain">CONFIRM NEW PASSWORD</label>
        <div className="newPasswordContainer">
          <input
            type={showNewPasswordAgain ? 'text' : 'password'}
            id="newPasswordAgain"
            name="newPasswordAgain"
            value={newPasswordAgain}
            onChange={handleNewPasswordChangeAgain}
            placeholder="Confirm new password"
          />
          <Button
            id="newPasswordButton"
            onClick={() => setShowNewPasswordAgain(!showNewPasswordAgain)}
            style={{
              height: '50px',
              width: '50px',
              backgroundColor: 'transparent',
              border: 'none',
            }}
          >
            {showNewPasswordAgain ? (
              <FaEyeSlash style={{ color: 'black' }} />
            ) : (
              <FaEye style={{ color: 'black' }} />
            )}
          </Button>
        </div>
        <input
          type="submit"
          onClick={handleChangePassword}
          style={{
            backgroundColor: loading ? 'gray' : 'green',
            cursor: loading ? 'not-allowed' : 'pointer',
          }}
          value="CHANGE PASSWORD"
        />
      </form>
      <div>
        <Toaster />
      </div>
    </div>
  )
}

export default ChangePassword
