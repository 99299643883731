import React, { useState, useRef } from 'react'
import pdfjsLib from 'pdfjs-dist'
import pdfFileString from './../download.pdf'

const PDFViewer = () => {
  const [rotation, setRotation] = useState(0) // Track rotation state
  const pdfViewerRef = useRef(null)
  const pdfSrc = URL.createObjectURL(
    new Blob([pdfFileString], { type: 'application/pdf' })
  )

  const handleRotate = (degrees) => {
    setRotation((prevRotation) => (prevRotation + degrees) % 360)
    pdfViewerRef.current.currentScaleValue = 1.0 // Reset zoom
  }

  const handleDownload = async () => {
    // Send rotation and PDF source to NodeJS server
    const response = await fetch('/download-rotated-pdf', {
      method: 'POST',
      body: JSON.stringify({ rotation, pdfSrc }),
    })

    const downloadUrl = await response.text()
    // Initiate download using the provided URL
  }

  return (
    <div>
      <canvas ref={pdfViewerRef} />
      <button onClick={() => handleRotate(90)}>Rotate Right</button>
      <button onClick={handleDownload}>Download</button>
    </div>
  )
}

export default PDFViewer
