import "./LoginForm.css";
import axios from 'axios';
import React, { useState, useEffect  } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {FaEye, FaEyeSlash} from 'react-icons/fa';

const LoginForm = ({ onAuthentication }) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL

  const handlePasswordChange = (e) => {
    setPassword(e.target.value)
    const newPassword = e.target.value.replace(/\s/g, '');
    setPassword(newPassword);
  };

  const handleUserNameChange = (e) => {
    setUsername(e.target.value)
    const newUserName = e.target.value.replace(/\s/g, '');
    setUsername(newUserName);
  };

  useEffect(() => {
    const authToken = localStorage.getItem('printer_authToken');
    if(!authToken){
      navigate('/');
    }else{
      navigate('/printer');
    }
  }, []);

  const handleLogin = async (e) => {
    toast.dismiss()
    e.preventDefault();
    setLoading(true);
    try {
      const response = await fetch(baseUrl+'/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          username: username,
          password: password,
        }),
      });

      if (response.ok) {
        toast.success('Login successful', { duration: 1000,
          position: 'top-center' });
        const responseData = await response.json();
        setLoading(false);
        // Save authentication token to local storage
        localStorage.setItem('printer_authToken', responseData.token);
        setAuthenticated(true);
        onAuthentication(true);
        // Handle other login logic if needed
        console.log('Login successful!');
        navigate('/printer');
      } else {
        setLoading(false);
        // Handle login error
        console.error('Login failed.');
        toast.error('Login failed.', { autoClose: 2000 });
      }
    } catch (error) {
      setLoading(false);
      console.error('An error occurred during login: ', error);
      toast.error('An error occurred during login: ', { autoClose: 2000 });
    }
  };

  return (
    <div id="login-form">
      <h1>TETON PRINTER</h1>
      <form>
        <label htmlFor="username">USERNAME</label>
        <input
          type="text"
          id="username"
          name="username"
          value={username}
          onChange={handleUserNameChange}
          placeholder="Enter your username or email"
        />
        <label htmlFor="password">PASSWORD</label>
        <div className="newPasswordContainer">
          <input
            type={showPassword ? 'text' : 'password'}
            id="password"
            name="password"
            value={password}
            onChange={handlePasswordChange}
            placeholder="Enter your password"

          />
          <Button 
              id="newPasswordButton" 
              onClick={() => setShowPassword(!showPassword)}
              style={{
                height: '50px',
                width: '50px',
                backgroundColor: 'transparent',
                border: 'none', 
            }}
          >
                  {showPassword ? <FaEyeSlash style={{ color: 'black' }}/> : <FaEye style={{ color: 'black' }} />}
          </Button>
        </div>
        
        <input type="submit" onClick={handleLogin}
        style={{
          backgroundColor: loading ? 'gray' : 'green',
          cursor: loading ? 'not-allowed' : 'pointer',
        }}
        value="LOG IN" />
      </form>

    </div>
  );
};

export default LoginForm;
