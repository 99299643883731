import React, { useState, useEffect } from 'react'
import { Table, Pagination } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import '../CustomPagination.css'
import { FaTrash } from 'react-icons/fa'
import toast, { Toaster } from 'react-hot-toast'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

const UserList = () => {
  const [printHistory, setPrintHistory] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(10) // Number of items to show per page
  const [totalItems, setTotalItems] = useState(0)
  const [userRole, setUserRole] = useState(false)
  const navigate = useNavigate()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth)
  const baseUrl = process.env.REACT_APP_BASE_URL
  const [loadingStates, setLoadingStates] = useState(
    Array(printHistory.length).fill(false)
  )

  const handlePageClick = (pageNumber) => {
    console.log('page number in handlepage: ', pageNumber)
    setCurrentPage(pageNumber)
  }

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  useEffect(() => {
    // This effect will be triggered after the state has been updated
    console.log('Fetching page: ', currentPage)
    fetchPrintHistory() // Call your API function here
  }, [currentPage])

  useEffect(() => {
    const checkTokenValidity = async () => {
      // Retrieve the token from local storage
      const authToken = localStorage.getItem('printer_authToken')

      if (!authToken) {
        // Token is not available, redirect to the login page
        navigate('/')
        return
      }

      try {
        // Check the validity of the token by making an API call
        const response = await fetch('https://wb.tetonltd.com/validateJwt', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: authToken,
          }),
        })

        if (!response.ok) {
          localStorage.removeItem('printer_authToken')
          // Token is not valid, redirect to the login page
          navigate('/')
        } else {
          const responseBody = await response.json()
          //this is test
          if (responseBody.data.data.role == 'admin') {
            setUserRole(true)
          }
        }
      } catch (error) {
        console.error('An error occurred during token validation:', error)
        // Handle error if needed
        navigate('/')
        return
      }
    }
    // Call the function to check token validity on every render
    checkTokenValidity()
    // Assuming you have an API endpoint to fetch print history
    fetchPrintHistory()
  }, [])

  const formatDateTime = (dateTimeString) => {
    // Parse the database time string into a Date object
    const databaseTimeDate = new Date(dateTimeString)
    // Get the formatted time string
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    }
    // Get the local string representation of the date and time
    const localTimeString = databaseTimeDate.toLocaleString('en-US', options)

    // const offset = 6; // UTC offset in hours

    // const convertedTime = new Date(databaseTimeDate.getTime() + offset * 60 * 60 * 1000);
    // const formattedTime = convertedTime.toLocaleString('en-US', options);

    // console.log(formattedTime);

    return localTimeString
  }

  const handleDelete = async (id, index) => {
    confirmAlert({
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            console.log(`Deleting user with id ${id}`)
            setLoadingStates((prevLoadingStates) => {
              const newLoadingStates = [...prevLoadingStates]
              newLoadingStates[index] = true
              return newLoadingStates
            })
            const authToken = localStorage.getItem('printer_authToken')
            try {
              const response = await fetch(baseUrl + '/deleteUser/' + id, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${authToken}`,
                },
              })

              if (response.ok) {
                setLoadingStates((prevLoadingStates) => {
                  const newLoadingStates = [...prevLoadingStates]
                  newLoadingStates[index] = false
                  return newLoadingStates
                })
                setPrintHistory((prevPrintHistory) => {
                  const newPrintHistory = [...prevPrintHistory]
                  newPrintHistory.splice(index, 1)
                  return newPrintHistory
                })
                toast.success('User deleted successfully', { autoClose: 2000 })
              } else {
                setLoadingStates((prevLoadingStates) => {
                  const newLoadingStates = [...prevLoadingStates]
                  newLoadingStates[index] = false
                  return newLoadingStates
                })
                const responseData = await response.json()
                const message = responseData.message

                console.log(message)
                toast.error(`Error: ${message}`, { autoClose: 2000 })
              }
            } catch (error) {
              setLoadingStates((prevLoadingStates) => {
                const newLoadingStates = [...prevLoadingStates]
                newLoadingStates[index] = false
                return newLoadingStates
              })
              console.error('An error occurred during creation: ', error)
              toast.error(`An error occurred during creation: ${error}`, {
                autoClose: 2000,
              })
            }
          },
        },
        {
          label: 'No',
          onClick: () => {
            // Do nothing if the user clicks "No"
          },
        },
      ],
    })
    // Your delete logic goes here, using the provided id
  }

  const truncateString = (str, max) => {
    return str.length > max ? str.substring(0, max - 3) + '...' : str
  }
  const fetchPrintHistory = async () => {
    try {
      console.log('page number in fetch: ', currentPage)
      const authToken = localStorage.getItem('printer_authToken')
      const offset = (currentPage - 1) * itemsPerPage

      const response = await fetch(
        baseUrl +
          `/getUserList?page=${currentPage}&limit=${itemsPerPage}&offset=${offset}`,
        {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        }
      )

      const data = await response.json()
      if (response.ok) {
        setPrintHistory(data.data)
        setTotalItems(data.totalItems)
      } else {
        console.error('Error fetching users')
        if (data.message === 'Invalid token') {
          navigate('/')
          return
        }
        // toast.error('Please provide a file to print', { autoClose: 2000 });
      }
    } catch (error) {
      console.error('Error fetching print history:', error)
    }
  }

  const totalPages = Math.ceil(totalItems / itemsPerPage)

  return (
    <div
      style={{
        width: '40%',
        margin: 'auto',
        ...(screenWidth <= 1000 && { width: '100%' }),
      }}
    >
      <div
        style={{
          backgroundColor: '#333',
          color: 'white',
          padding: '10px',
          textAlign: 'center',
          fontSize: '24px',
          fontWeight: 'bold',
          ...(screenWidth <= 1000 && { width: '100%' }),
        }}
      >
        USER LIST
      </div>
      <Table striped bordered hover variant="light">
        <thead>
          <tr style={{ textAlign: 'center' }}>
            <th>User Name</th>
            {/* <th>Password</th> */}
            <th>Email</th>
            {userRole ? <th>Role</th> : null}
            <th>User Created</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody style={{ backgroundColor: 'gray' }}>
          {printHistory.map((print, index) => (
            <tr
              key={print.id}
              style={{
                backgroundColor: index % 2 === 0 ? 'gray' : 'lightgray',
              }}
            >
              {userRole ? (
                <td title={print.name}>
                  {' '}
                  {truncateString(print.name.toString(), 10)}
                </td>
              ) : null}
              <td>{print.email}</td>
              {/* <td>{print.password}</td> */}
              <td>{print.role}</td>
              <td>{formatDateTime(print.created_at)}</td>
              <td>
                {loadingStates[index] ? (
                  // Display loading spinner while deleting
                  <div>Loading...</div>
                ) : (
                  // Display delete icon when not loading
                  <FaTrash
                    style={{ cursor: 'pointer' }}
                    onClick={() => handleDelete(print.id, index)}
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Pagination className="custom-pagination">
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => handlePageClick(currentPage - 1)}
        />
        {[...Array(totalPages)].map((_, index) => (
          <Pagination.Item
            key={index + 1}
            active={currentPage === index + 1}
            onClick={() => handlePageClick(index + 1)}
          >
            {index + 1}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={currentPage === totalPages}
          onClick={() => handlePageClick(currentPage + 1)}
        />
      </Pagination>
      <div>
        <Toaster />
      </div>
    </div>
  )
}

export default UserList
