import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Offcanvas, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const MyNavbar = () => {
    const navigate = useNavigate();
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [userRole, setUserRole] = useState(false);
    const [userName, setUserName] = useState('');
    const baseUrl = process.env.REACT_APP_BASE_URL


    useEffect(() => {
      const checkTokenValidity = async () => {
        const authToken = localStorage.getItem('printer_authToken');
        try {
          // Check the validity of the token by making an API call
          const response = await fetch(baseUrl+'/validateJwt', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              token: authToken,
            }),
          });
          
          if (response.ok) {
            const responseBody = await response.json();
            const userRole = responseBody.data.data.role;
            const name = responseBody.data.data.name;
            setUserName(name);
            console.log(responseBody);
            if(userRole==='admin'){
              setUserRole(true);
            }
          }
        } catch (error) {
          console.error('An error occurred during token validation:', error);
          // Handle error if needed
          // navigate('/');
          return;
        }
      };
      checkTokenValidity();
    }, []);

    const handleToggle = () => setShowOffcanvas((prev) => !prev);
    const handleLogout = () => {
      handleToggle()
        // Remove the printer_authToken from local storage
        localStorage.removeItem('printer_authToken');
        // Redirect to the login page
        navigate('/');
    };
  return (
    <Navbar fixed="top" bg="transparent" variant="light">
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {/* Other navigation items can be added here */}
        </Nav>
        <label style={{ fontWeight: 'bold', color: 'green' }}>Logged In as {userName}</label>

        
      </Navbar.Collapse>
      <>
      <Button variant="black" onClick={handleToggle} style={{ border: '1px solid black' }}>
        ☰
      </Button>


      <Offcanvas show={showOffcanvas} onHide={handleToggle} placement="end" backdropClassName="offcanvas-backdrop">
        <Offcanvas.Header style={{ backgroundColor: 'black', color: 'white' }}>
          <Offcanvas.Title>OPTIONS</Offcanvas.Title>
          <button
            type="button"
            className="btn-close btn-close-white"
            aria-label="Close"
            style={{ color: 'white' }}
            onClick={handleToggle}
        ></button>
        </Offcanvas.Header>
        <Offcanvas.Body style={{ backgroundColor: 'black', color: 'white' }}>
          <ul>
            <li>
              <Link to="/printer" style={{ textDecoration: 'none', color: 'white' }} onClick={handleToggle}>
                PRINTER
              </Link>
            </li>
            <li>
              <Link to="/changePassword" style={{ textDecoration: 'none', color: 'white' }} onClick={handleToggle}>
                CHANGE PASSWORD
              </Link>
            </li>
            <li>
              <Link to="/printHistory" style={{ textDecoration: 'none', color: 'white' }} onClick={handleToggle}>
                PRINT HISTORY
              </Link>
            </li>
            <li>
                <Link to="/" style={{ textDecoration: 'none', color: 'white' }} onClick={handleLogout}>
                LOGOUT
                </Link>
            </li>
            {userRole && (
              <li>
                <Link to="/createUser" style={{ textDecoration: 'none', color: 'white' }} onClick={handleToggle}>
                  ADD USER
                </Link>
              </li>
            )}
            {userRole && (
              <li>
                <Link to="/userList" style={{ textDecoration: 'none', color: 'white' }} onClick={handleToggle}>
                  USER LIST
                </Link>
              </li>
            )}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
    </Navbar>
  );
};

export default MyNavbar;
